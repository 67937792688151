import React, { useEffect, useState } from "react";
import CustomDropDown from "../../Custom/CustomDropDown";
import CustomButton from "../../Custom/CustomButton";
import CustomTextarea from "../../Custom/CustomTextarea";
import { useFormik } from "formik";
import api from "../../Composables/api";
import CustomModal from "../../Custom/CustomMessageModal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import { createQuestionValidationSchema } from "../../Assets/ValidationSchema";
import {
  AnswerType,
  DEPARTMENT_CHOICES,
  Organisation_type,
  environmentChoice,
  governanceChoice,
  regulation_choice,
  regulations,
  section_choice,
  socialChoice,
} from "./Assets";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Form, Input, InputNumber, Radio } from "antd";
import Sidebar from "../../components/Sidebar";
import CustomHeader from "../../Custom/CustomHeader";
import {
  ANSWER_WEIGHT,
  Master_User,
  QUESTION_RANGES,
  Super_User,
} from "../../Assets/Constant";
import CustomInputNumber from "../../Custom/InputNumber";
import { CloseOutlined } from "@ant-design/icons";

const CreateQuestion = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState("");
  const [showTextarea, setShowTextarea] = useState("");
  const [ranges, setRanges] = useState(QUESTION_RANGES);
  const [score, setScore] = useState(ANSWER_WEIGHT);
  const [subRanges, setSubRanges] = useState(QUESTION_RANGES);
  const [subQuestions, setSubQuestions] = useState([
    {
      question: "",
      remarks: "",
      answer_type: "",
      score: ANSWER_WEIGHT,
    },
  ]);

  const userRole = localStorage.getItem("userRole");

  const handleSubQuestionChange = (index, field, value) => {
    const newSubQuestions = [...subQuestions];
    newSubQuestions[index][field] = value;
    setSubQuestions(newSubQuestions);
  };

  const handleSubScoreChange = (subIndex, scoreType, newValue) => {
    setSubQuestions((prevSubQuestions) => {
      const newSubQuestions = [...prevSubQuestions];
      newSubQuestions[subIndex] = {
        ...newSubQuestions[subIndex],
        score: {
          ...newSubQuestions[subIndex].score,
          [scoreType]: newValue,
        },
      };
      return newSubQuestions;
    });
  };

  const closeModal = (value) => {
    setOpen(value);
    navigate("/questionmanagement");
  };

  const navigate = useNavigate();

  const handleClosed = () => {
    setOpen2(false);
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/questionmanagement");
  };

  const closeModal1 = (value) => {
    setOpen2(value);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const getQuestions = async () => {
    try {
      if (id) {
        const response = await api.get(`/questions/?question_id=${id}`);
        setData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuestions();
  }, [id]);

  const initialValues = {
    question: data?.question ?? "",
    remarks: data?.remarks ?? "",
    department: data?.department ?? [],
    section: data?.section ?? "",
    sub_section: data?.sub_section ?? "",
    regulation: data?.regulation ?? [],
    show_dependent: "",
    sub_questions: [],
    organization_sector: data?.organization_sector ?? [],
    answer_type: data?.answer_type ?? "",
    score: {},
  };

  const getJsonKeyValue = (range_array) => {
    const jsonKey = range_array
      .filter((item) => item?.key)
      .reduce((acc, range) => {
        acc[range.key] = range.value;
        return acc;
      }, {});
    return jsonKey;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createQuestionValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (values.regulation.includes("All")) {
        values.regulation = regulations;
      }
      if (values.answer_type === "Range") {
        const rangeObject = getJsonKeyValue(ranges);
        values.score = {
          na: score.na,
          no: score.no,
          yes: score.yes,
          range: rangeObject,
        };
      } else {
        values.score = score;
      }
      values.show_dependent = showTextarea;
      if (values.show_dependent) {
        if (
          subQuestions[0]?.question === "" ||
          subQuestions[0]?.remarks === "" ||
          subQuestions[0]?.answer_type === ""
        ) {
          delete values.sub_questions;
        } else {
          values.sub_questions = subQuestions.map((subQuestion, index) => ({
            ...subQuestion,
            score: {
              ...subQuestion.score,
              range: getJsonKeyValue(subRanges),
            },
          }));
        }
      } else {
        delete values.sub_questions;
      }
      try {
        setLoading(true);
        if (data?.id) {
          if (userRole === Master_User) {
            delete values?.organization_sector;
          }
          await api.put(`/questions/?question_id=${data.id}`, values);
          setOpen(true);
          formik.resetForm();
          setMessages("Updated");
        } else {
          await api.post("/questions/", values);
          setOpen(true);
          setMessages("Created");
        }
      } catch (error) {
        setOpen2(true);
        if (error?.response?.status === 400 && error?.response?.data) {
          if (Array.isArray(error.response.data)) {
            const errorMessage = Object.values(error.response.data)[0][0];
            setMessages(errorMessage);
          } else {
            setMessages(Object.values(error?.response?.data));
          }
        } else {
          setMessages(error?.response?.data?.message);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  let Sub_section_choice = "";

  if (formik.values.section === "Environment") {
    Sub_section_choice = environmentChoice;
  } else if (formik.values.section === "Social") {
    Sub_section_choice = socialChoice;
  } else {
    Sub_section_choice = governanceChoice;
  }

  const handleRangeKeyChange = (index, newKey) => {
    setRanges((prevRanges) =>
      prevRanges.map((range, i) =>
        i === index ? { ...range, key: newKey } : range
      )
    );
  };

  const handleRangeValueChange = (index, newValue) => {
    setRanges((prevRanges) =>
      prevRanges.map((range, i) =>
        i === index ? { ...range, value: newValue } : range
      )
    );
  };

  const handleSubRangeKeyChange = (index, newKey) => {
    setSubRanges((prevRanges) =>
      prevRanges.map((range, i) =>
        i === index ? { ...range, key: newKey } : range
      )
    );
  };

  const handleSubRangeValueChange = (index, newValue) => {
    setSubRanges((prevRanges) =>
      prevRanges.map((range, i) =>
        i === index ? { ...range, value: newValue } : range
      )
    );
  };

  return (
    <>
      <Sidebar>
        <CustomHeader />
        <CustomModal
          open={open}
          handleClose={handleClose}
          children={
            <SuccessMessage
              img={Tick}
              message={`Question ${messages} successfully !`}
              desc={`Thank you question ${messages} successfully!`}
              colors="bg-[#32D52B]"
              modalClose={closeModal}
            />
          }
        />
        <CustomModal
          open={open2}
          handleClose={handleClosed}
          children={
            <SuccessMessage
              img={Cross}
              message={messages || "Question creation failed"}
              desc={`There is an issue , please check !`}
              colors="bg-[#F73232]"
              modalClose={closeModal1}
            />
          }
        />
        <div className="p-5  flex flex-col gap-7 md:w-[80%] sm:w-100 w-100">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h2>Create Question</h2>
            <Divider />
            <form
              onSubmit={formik.handleSubmit}
              className=" flex flex-col gap-5"
            >
              <CustomTextarea
                label="Question Text"
                placeholder="Write your question here"
                height="120px"
                id="question"
                name="question"
                required={true}
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.question && formik.errors.question}
              />
              <CustomTextarea
                label="Remarks"
                placeholder="Write your remarks here"
                height="80px"
                id="remarks"
                name="remarks"
                required={true}
                value={formik.values.remarks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.remarks && formik.errors.remarks}
              />

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <CustomDropDown
                  options={DEPARTMENT_CHOICES}
                  dropdownLabel="Department"
                  className="w-full"
                  styleClassName="bg-white"
                  id="department"
                  multiple={true}
                  name="department"
                  required={true}
                  value={formik.values.department}
                  onChange={(value) =>
                    formik.setFieldValue("department", value)
                  }
                  onBlur={() => formik.setFieldTouched("department")}
                  error={formik.touched.department && formik.errors.department}
                />
                <CustomDropDown
                  options={section_choice}
                  required={true}
                  className="w-full"
                  styleClassName="bg-white"
                  dropdownLabel="Question type (Environment, Social or Governance)"
                  id="section"
                  name="section"
                  value={formik.values.section}
                  onChange={(value) => formik.setFieldValue("section", value)}
                  onBlur={() => formik.setFieldTouched("section")}
                  error={formik.touched.section && formik.errors.section}
                />
                <CustomDropDown
                  options={Sub_section_choice}
                  required={true}
                  className="w-full"
                  styleClassName="bg-white"
                  dropdownLabel="Sub Section(Question relates to)"
                  id="sub_section"
                  name="sub_section"
                  value={formik.values.sub_section}
                  onChange={(value) =>
                    formik.setFieldValue("sub_section", value)
                  }
                  onBlur={() => formik.setFieldTouched("sub_section")}
                  error={
                    formik.touched.sub_section && formik.errors.sub_section
                  }
                />
                <CustomDropDown
                  options={regulation_choice}
                  required={true}
                  className="w-full"
                  styleClassName="bg-white"
                  dropdownLabel="Regulation"
                  id="regulation"
                  multiple={true}
                  name="regulation"
                  value={formik.values.regulation}
                  onChange={(value) =>
                    formik.setFieldValue("regulation", value)
                  }
                  onBlur={() => formik.setFieldTouched("regulation")}
                  error={formik.touched.regulation && formik.errors.regulation}
                />
                {userRole === Super_User && (
                  <CustomDropDown
                    placeholder="Select Organization"
                    dropdownLabel="Organisation sector"
                    id="organization_sector"
                    name="organization_sector"
                    className="w-100 py-2 rounded-xl"
                    multiple
                    options={Organisation_type}
                    value={formik.values.organization_sector}
                    onChange={(value) =>
                      formik.setFieldValue("organization_sector", value)
                    }
                    onBlur={() => formik.setFieldTouched("organization_sector")}
                    error={
                      formik.touched.organization_sector &&
                      formik.errors.organization_sector
                    }
                  />
                )}
                <CustomDropDown
                  placeholder="Select Answer type"
                  dropdownLabel="Answer type"
                  id="answer_type"
                  name="answer_type"
                  className="w-100 py-2 rounded-xl"
                  options={AnswerType}
                  value={formik.values.answer_type}
                  onChange={(value) =>
                    formik.setFieldValue("answer_type", value)
                  }
                  onBlur={() => formik.setFieldTouched("answer_type")}
                  error={
                    formik.touched.answer_type && formik.errors.answer_type
                  }
                />
              </div>
              {formik.values.answer_type === "Range" && (
                <Form
                  layout="vertical  "
                  className="grid md:grid-cols-2 grid-col-1 gap-5"
                >
                  {ranges?.map((range, index) => (
                    <div key={index} className="flex gap-4">
                      <Form.Item
                        label={`Range ${index + 1} Key`}
                        style={{ flex: 1 }}
                      >
                        <Input
                          placeholder="e.g. 0-25hours"
                          defaultValue={range.key}
                          onBlur={(e) =>
                            handleRangeKeyChange(index, e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={`Range ${index + 1} Value`}
                        style={{ flex: 1 }}
                      >
                        <InputNumber
                          min={0}
                          max={9}
                          defaultValue={range.value}
                          onChange={(value) =>
                            handleRangeValueChange(index, value)
                          }
                        />
                      </Form.Item>
                    </div>
                  ))}
                </Form>
              )}
              <div className="flex gap-4">
                {(formik.values.answer_type === "Yes/No/NA" ||
                  formik.values.answer_type === "Yes/No") && (
                  <>
                    <CustomInputNumber
                      min={0}
                      max={9}
                      label="Yes"
                      defaultValue={score.yes}
                      onChange={(value) =>
                        setScore({ yes: value, no: score.no, na: score.na })
                      }
                    />
                    <CustomInputNumber
                      min={0}
                      max={9}
                      label="No"
                      defaultValue={score.no}
                      onChange={(value) =>
                        setScore({ no: value, yes: score.yes, na: score.na })
                      }
                    />
                    {formik.values.answer_type === "Yes/No/NA" && (
                      <CustomInputNumber
                        min={0}
                        max={9}
                        label="NA"
                        defaultValue={score.na}
                        onChange={(value) =>
                          setScore({
                            na: value,
                            yes: score.yes,
                            no: score.no,
                          })
                        }
                      />
                    )}
                  </>
                )}
              </div>

              {(formik.values.answer_type === "Yes/No" ||
                formik.values.answer_type === "Yes/No/NA") && (
                <div className="flex flex-col gap-4">
                  <div>Linked Sub Question</div>
                  <div className="flex items-center">
                    <Radio.Group
                      onChange={(e) => {
                        setShowTextarea(e.target.value);
                      }}
                      value={showTextarea}
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                    <CloseOutlined
                      className="cursor-pointer rounded-3xl bg-slate-400 hover:bg-slate-200 p-1.5"
                      onClick={() => setShowTextarea("")}
                    />
                  </div>
                </div>
              )}
              {showTextarea && (
                <>
                  {subQuestions.map((subQuestion, index) => (
                    <div key={index}>
                      <CustomTextarea
                        height="80px"
                        label={`Sub Question ${index + 1}`}
                        placeholder="Sub Question"
                        id={`subQuestion.question[${index}]`}
                        name={`subQuestion.question[${index}]`}
                        value={subQuestion.question}
                        onChange={(e) =>
                          handleSubQuestionChange(
                            index,
                            "question",
                            e.target.value
                          )
                        }
                        onBlur={() => {}}
                        error={false}
                      />
                      <CustomTextarea
                        label="Remarks"
                        placeholder="Write your remarks here"
                        height="80px"
                        id={`subQuestion.remarks[${index}]`}
                        name={`subQuestion.remarks[${index}]`}
                        value={subQuestion.remarks}
                        onChange={(e) =>
                          handleSubQuestionChange(
                            index,
                            "remarks",
                            e.target.value
                          )
                        }
                        onBlur={() => {}}
                        error={false}
                      />
                      <CustomDropDown
                        placeholder="Select Answer type"
                        dropdownLabel="Answer type"
                        className="w-100 py-2 rounded-xl"
                        id={`subQuestion.answer_type[${index}]`}
                        name={`subQuestion.answer_type[${index}]`}
                        options={AnswerType}
                        value={subQuestion.answer_type}
                        onChange={(value) =>
                          handleSubQuestionChange(index, "answer_type", value)
                        }
                        onBlur={() => {}}
                        error={false}
                      />
                      {subQuestion.answer_type === "Range" && (
                        <Form
                          layout="vertical"
                          className="grid md:grid-cols-2 grid-col-1 gap-5"
                        >
                          {subRanges.map((range, rangeIndex) => (
                            <div key={rangeIndex} className="flex gap-4">
                              <Form.Item
                                label={`Range ${rangeIndex + 1} Key`}
                                style={{ flex: 1 }}
                              >
                                <Input
                                  placeholder="e.g. 0-25hours"
                                  value={range.key}
                                  onChange={(e) =>
                                    handleSubRangeKeyChange(
                                      rangeIndex,
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                label={`Range ${rangeIndex + 1} Value`}
                                style={{ flex: 1 }}
                              >
                                <InputNumber
                                  min={0}
                                  max={9}
                                  value={range.value}
                                  onChange={(value) =>
                                    handleSubRangeValueChange(rangeIndex, value)
                                  }
                                />
                              </Form.Item>
                            </div>
                          ))}
                        </Form>
                      )}
                      {(subQuestion.answer_type === "Yes/No/NA" ||
                        subQuestion.answer_type === "Yes/No") && (
                        <div className="flex gap-4">
                          <CustomInputNumber
                            min={0}
                            max={9}
                            label="Yes"
                            defaultValue={subQuestion.score.yes}
                            onChange={(value) =>
                              handleSubScoreChange(index, "yes", value)
                            }
                          />
                          <CustomInputNumber
                            min={0}
                            max={9}
                            label="No"
                            defaultValue={subQuestion.score.no}
                            onChange={(value) =>
                              handleSubScoreChange(index, "no", value)
                            }
                          />
                          {subQuestion.answer_type === "Yes/No/NA" && (
                            <CustomInputNumber
                              min={0}
                              max={9}
                              label="NA"
                              defaultValue={subQuestion.score.na}
                              onChange={(value) =>
                                handleSubScoreChange(index, "na", value)
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}

              <div className="flex gap-3">
                <CustomButton
                  type="submit"
                  title={loading ? "Loading .." : "Create question"}
                  disabled={loading}
                  onClick={() => {}}
                ></CustomButton>
                <CustomButton
                  title="Cancel"
                  onClick={() => navigate("/questionmanagement")}
                ></CustomButton>
              </div>
            </form>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateQuestion;
