import React, { useEffect, useState } from "react";
import CustomButton from "../../Custom/CustomButton";
import api from "../../Composables/api";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CustomModal from "../../Custom/CustomMessageModal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import Input from "../../Custom/LoginInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Super_User } from "../../Assets/Constant";
import Sidebar from "../../components/Sidebar";
import CustomHeader from "../../Custom/CustomHeader";

const validationSchema = Yup.object().shape({
  new_expiry_date: Yup.string().required("Valid till is required"),
});

const UserDetailsApprove = () => {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [approveMsg, setApproveMsg] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showValid, setShowValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate("/users");
  };

  const closeModal = (value) => {
    setOpen(value);
    navigate("/users");
  };

  const closeModal1 = () => {
    setOpen2(false);
  };

  const handleClosed = () => {
    setOpen2(false);
  };
  const userRole = localStorage.getItem("userRole");
  const getUserDetails = async () => {
    try {
      const response = await api.get(`/user/?user_id=${id}`);
      setData(response?.data);
      if (response?.data?.status === "PENDING" && userRole === Super_User) {
        setShowButton(true);
      }
      if (response?.data?.status === "APPROVED" && userRole === Super_User) {
        setShowValid(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    new_expiry_date: data?.valid_till,
    user_id: id,
    action: "approve",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await api.put("/approve-user/", values);
        setOpen(true);
        setLoading(false);
        setApproveMsg("User approved successfully!");
      } catch (err) {
        console.log(err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
        setOpen2(true);
        setErrMsg(err?.response?.data?.message);
      }
    },
  });

  const handleDecline = async () => {
    try {
      setLoading(true);
      const response = await api.put("/approve-user/", {
        user_id: id,
        action: "decline",
      });
      setApproveMsg("User declined successfully !");
      setLoading(false);
      setOpen(true);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
      setOpen2(true);
      setErrMsg(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <Sidebar>
        <CustomHeader />
        <CustomModal
          open={open}
          handleClose={handleClose}
          children={
            <SuccessMessage
              img={Tick}
              message={approveMsg}
              desc=""
              colors="bg-[#32D52B]"
              modalClose={closeModal}
            />
          }
        />
        <CustomModal
          open={open2}
          handleClose={handleClosed}
          children={
            <SuccessMessage
              img={Cross}
              message={errMsg}
              desc=""
              colors="bg-[#F73232]"
              modalClose={closeModal1}
            />
          }
        />
        <div className="flex justify-center p-5">
          <div className="flex flex-col  items-stretch w-[71%] ml-5 max-md:w-full max-md:ml-0">
            <div className="items-stretch shadow-sm bg-stone-50 flex flex-col w-full my-auto pb-7 max-md:max-w-full max-md:mt-10">
              <span className="text-white text-2xl font-semibold leading-7 bg-indigo-500 justify-center pl-7 pr-16 py-6 items-start max-md:max-w-full max-md:px-5">
                Green world request awaiting for approval
              </span>
              <span className="flex w-[830px] max-w-full flex-col md:p-5 p-2 mt-7 self-start items-start">
                <div className=" text-sm leading-5 self-stretch max-md:max-w-full ">
                  <span className="">
                    You have been assigned as an approver for this Green world
                    request, please use the link at the bottom if this Email to
                    be taken to Green world dashboard and use the Approve or
                    Reject action from the drop down list for Ram Bros & Co
                    Requester’s request to use access profile.
                  </span>
                </div>
                <div className="self-stretch flex items-stretch justify-between gap-5 mt-12 pr-20 max-md:max-w-full max-md:flex-wrap max-md:mt-10 max-md:pr-5">
                  <div className="items-stretch flex grow basis-[0%] flex-col self-start">
                    <div className="text-stone-500 text-sm leading-5 tracking-wide">
                      First Name
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.first_name}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide">
                      Last Name
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.last_name}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      E-Mail ID
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.email}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      Company Name
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.company_name}
                    </div>

                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      Licence valid for
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {userRole === Super_User ? (
                        <Input
                          type="date"
                          placeholder="Licence Valid till"
                          title="Licence Valid till"
                          className="w-100"
                          name="new_expiry_date"
                          id="new_expiry_date"
                          min={today}
                          required
                          value={formik.values.new_expiry_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.new_expiry_date &&
                            formik.errors.new_expiry_date
                          }
                        />
                      ) : (
                        data?.valid_till
                      )}
                    </div>
                  </div>
                  <div className="bg-stone-300 w-px md:block hidden shrink-0 h-[320px]" />
                  <div className="items-stretch flex grow basis-[0%] flex-col self-start">
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      What sector is your organisation in?
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.organization_sector.join(",")}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      Where is your organisation based?
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.organization_based}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      No of Employees
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.no_of_employees}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      Role
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.role}
                    </div>
                    <div className="text-stone-500 text-sm leading-5 tracking-wide mt-4">
                      Last access time
                    </div>
                    <div className="text-black text-base leading-5 tracking-wide mt-2">
                      {data?.last_access_datetime}
                    </div>
                  </div>
                </div>
                {showButton && (
                  <div className="items-stretch flex gap-4 mt-12 max-md:mt-10">
                    <CustomButton
                      title="Decline"
                      onClick={handleDecline}
                      className="bg-white border px-4 text-base hover:bg-purple-100 border-[#5D5FEF] text-[#5D5FEF]"
                    />
                    <CustomButton
                      title={loading ? "Loading .." : "Approve"}
                      onClick={formik.handleSubmit}
                      className="px-4 text-base bg-[#5D5FEF] text-white hover:bg-[#686ad0] border-[#686ad0]"
                    />
                  </div>
                )}
                {showValid && (
                  <CustomButton
                    title={loading ? "Loading .." : "Extend Date"}
                    onClick={formik.handleSubmit}
                    className="px-4 text-base bg-[#5D5FEF] text-white hover:bg-[#686ad0] border-[#686ad0]"
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default UserDetailsApprove;
