import React from "react";
import { useNavigate } from "react-router-dom";
import { Master_User, Super_User } from "../../Assets/Constant";

const CustomHeader = (props) => {
  const navigate = useNavigate();

  const userRole = localStorage.getItem("userRole");

  const handleNavigate = () => {
    if (userRole === Super_User || userRole === Master_User) {
      navigate("/users");
    } else {
      navigate("#");
    }
  };
  return (
    <>
      <div className="bg-white items-center z-[2] px-5 h-[4.5rem] shadow">
        <div className="flex justify-between items-center">
          <h1
            className="text-[#5D5FEF] items-center flex justify-center cursor-pointer md:text-2xl text-xl"
            onClick={handleNavigate}
          >
            <img src={props.logo} alt="" width={200} />
          </h1>
          <div>{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default CustomHeader;
