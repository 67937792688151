import { Badge } from "antd";
import PublishDocument from "../../../components/PublishDocument";
import SuperAdminAssessment from "../../../components/SuperAdminAssessment";

const handleActionClick = (event) => {
  event.stopPropagation();
};

export const SuperReportColumn = [
  {
    title: "Edit",
    dataIndex: "user_id",
    key: "actions",
    width: 60,
    render: (record, text) => (
      <>
        <div className="flex gap-2 z-[100]" onClick={handleActionClick}>
          <SuperAdminAssessment data={text} />
        </div>
      </>
    ),
  },
  {
    title: "First Name ",
    dataIndex: "user_info",
    width: 130,
    render: (record) => (
      <Badge
        className="site-badge-count-109"
        count={record?.first_name}
        style={{
          backgroundColor: "#5D5FEF",
          borderRadius: "5px",
          fontSize: "1rem",
        }}
      />
    ),
  },
  {
    title: "Organisation",
    dataIndex: "user_info",
    width: 150,
    render: (record) => <>{record?.organization}</>,
  },
  {
    title: " Environment",
    dataIndex: "section_wise_scores",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">
        {Math.round(record?.Environment)} %
      </h2>
    ),
  },
  {
    title: " Social",
    dataIndex: "section_wise_scores",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">
        {Math.round(record?.Social)} %
      </h2>
    ),
  },
  {
    title: " Governance",
    dataIndex: "section_wise_scores",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">
        {Math.round(record?.Governance)} %
      </h2>
    ),
  },
  {
    title: " Total Score",
    dataIndex: "total_score",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">{Math.round(record)} %</h2>
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: 140,
    render: (record, text) => (
      <>
        <div className="flex gap-2 z-[100]" onClick={handleActionClick}>
          <PublishDocument data={text} />
        </div>
      </>
    ),
  },
];

export const MasterReportColumn = [
  {
    title: "Organisation",
    dataIndex: "user_info",
    width: 150,
    render: (record) => (
      <Badge
        className="site-badge-count-109"
        count={record?.organization}
        style={{
          backgroundColor: "#5D5FEF",
          borderRadius: "5px",
          fontSize: "1rem",
        }}
      />
    ),
  },
  {
    title: " Environment",
    dataIndex: "section_wise_scores",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">
        {Math.round(record?.Environment)} %
      </h2>
    ),
  },
  {
    title: " Social",
    dataIndex: "section_wise_scores",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">
        {Math.round(record?.Social)} %
      </h2>
    ),
  },
  {
    title: " Governance",
    dataIndex: "section_wise_scores",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">
        {Math.round(record?.Governance)} %
      </h2>
    ),
  },
  {
    title: " Total Score",
    dataIndex: "total_score",
    width: 160,
    render: (record) => (
      <h2 className=" text-[#5D5FEF] font-bold">{Math.round(record)} %</h2>
    ),
  },
];
