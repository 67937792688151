import React, { useEffect, useState } from "react";
import CustomSearch from "../../Custom/CustomSearch";
import FilterIcon from "../../Images/FilterIcon.svg";
import CustomTable from "../../Custom/CustomTable";
import CustomButton from "../../Custom/CustomButton";
import { PlusOutlined } from "@ant-design/icons";
import AddUser from "../../components/AddUser";
import CustomModal from "../../Custom/Modal";
import toast from "react-hot-toast";
import api from "../../Composables/api";
import CustomTab from "../../Custom/CustomTab";
import { pendingUserColumn } from "./Assets";
import AddSubUser from "../../components/AddSubUser";
import { Master_User, Super_User } from "../../Assets/Constant";
import Sidebar from "../../components/Sidebar";
import CustomHeader from "../../Custom/CustomHeader";
import useModalOpen from "../../Custom/CustomModalOpen/useModalOpen";

const UserManagement = () => {
  const [datas, setDatas] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [approveData, setApproveData] = useState([]);
  const [declineData, setDeclineData] = useState([]);
  const onSearch = (value) => {
    console.log(value);
  };

  const UserRole = localStorage.getItem("userRole");
  const [open, showModal, handleClose, closeModal] = useModalOpen();

  const getAllPendingUser = async () => {
    try {
      const response = await api.get("/list-users/");
      const pendingUsers = response?.data.filter(
        (user) => user?.status === "PENDING"
      );
      setPendingData(pendingUsers);
      const approvedUsers = response?.data.filter(
        (user) => user?.status === "APPROVED"
      );
      setApproveData(approvedUsers);
      const declineUsers = response?.data.filter(
        (user) => user?.status === "DECLINED"
      );
      setDeclineData(declineUsers);
      setDatas(response?.data);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  let title = "";
  if (UserRole === Super_User) {
    title = "Add User";
  } else if (UserRole === Master_User) {
    title = "Add Sub User";
  }

  const items = [
    {
      key: "1",
      label: "All",
      children: (
        <CustomTable
          columnData={pendingUserColumn}
          url="/userdetails"
          scrollWidth="1500"
          data={datas}
        />
      ),
    },
    {
      key: "2",
      label: "Pending",
      children: (
        <CustomTable
          columnData={pendingUserColumn}
          url="/userdetails"
          scrollWidth="1500"
          data={pendingData}
        />
      ),
    },
    {
      key: "3",
      label: "Approved",
      children: (
        <CustomTable
          columnData={pendingUserColumn}
          url="/userdetails"
          scrollWidth="1500"
          data={approveData}
        />
      ),
    },
    {
      key: "4",
      label: "Declined",
      children: (
        <CustomTable
          columnData={pendingUserColumn}
          url="/userdetails"
          scrollWidth="1500"
          data={declineData}
        />
      ),
    },
  ];

  const handleApiCall = () => {
    getAllPendingUser();
  };

  useEffect(() => {
    getAllPendingUser();
  }, []);

  return (
    <>
      <Sidebar>
        <CustomHeader>
          <CustomButton title={title} onClick={showModal}>
            <PlusOutlined />
          </CustomButton>
        </CustomHeader>
        <CustomModal
          open={open}
          title={title}
          onCancel={handleClose}
          width="600px"
        >
          {UserRole === Super_User ? (
            <AddUser modalClose={handleClose} apiCall={handleApiCall} />
          ) : null}
          {UserRole === Master_User ? (
            <AddSubUser modalClose={handleClose} apiCall={handleApiCall} />
          ) : null}
        </CustomModal>
        <div className="p-5">
          <h2 className="text-black text-2xl font-medium self-stretch max-md:max-w-full">
            User Management
          </h2>
          <div className="mt-3 flex flex-wrap gap-7"></div>
          <CustomTab items={items} />
        </div>
      </Sidebar>
    </>
  );
};

export default UserManagement;
