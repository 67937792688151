import { useFormik } from "formik";
import React, { memo, useState } from "react";
import Input from "../../Custom/LoginInput";
import toast from "react-hot-toast";
import CustomButton from "../../Custom/CustomButton";
import { MasterUserValidationSchema } from "../../Assets/ValidationSchema";
import { api } from "../../Composables/api";
import CustomModal from "../../Custom/Modal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import DropDown from "../../Custom/CustomDropDown";
import { Organisation_type, countries } from "../../pages/Register/Assets";
import { regulation_choice } from "../../pages/CreateQuestion/Assets";
import {
  Master_User,
  Super_User,
  consolidator_user,
} from "../../Assets/Constant";
import { Link } from "react-router-dom";

const AddUser = (props) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consolidator, setConsolidator] = useState("master");
  const [errorMessage, showErrorMessage] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const userRole = localStorage.getItem("userRole");

  const handleClose = () => {
    setOpen(false);
  };

  const closeModal = (value) => {
    setOpen(value);
  };

  const handleClosed = () => {
    setOpen2(false);
  };

  const closeModal1 = (value) => {
    setOpen2(value);
  };

  const handleCancel = () => {
    props.modalClose(false);
  };

  const No_Of_Employees = [
    { value: "0-10", label: "0-10" },
    { value: "10-25", label: "10-25" },
    { value: "25-50", label: "25-50" },
    { value: "50-100", label: "50-100" },
    { value: "100+", label: "100+" },
  ];

  const initialValues = {
    user: {
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      valid_till: props?.licenseDate || "",
      regulation: props?.regulation || [],
      liscense_count: "",
    },
    organization_sector: [],
    organization_based: "",
    company_name: "",
    no_of_employees: "",
  };

  const regulations = [
    "BRSR",
    "CII",
    "IGBC",
    "LEED",
    "SDG",
    "TCFD",
    "CDP",
    "GRI",
    "SBT",
    "ESRS",
    "UNGC",
    "GoodPractice",
    "SwachhBharatMission",
    "SASB",
    "CentralBanks",
    "WBCSD",
    "WBA",
    "WHO",
    "GoodGovernancePractice",
    "MCA",
    "IFRS",
    "OECD",
    "ILO",
    "FATF",
    "UNEP",
    "CSRD",
    "UNEPSI",
    "UNGP",
  ];

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MasterUserValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (values.user.regulation.includes("All")) {
        values.user.regulation = regulations;
      }
      try {
        let url = "";
        if (consolidator === "consolidator") {
          url = "/create-consolidator-user/";
          values.user.role = consolidator_user;
        } else if (consolidator === "master") {
          url = "/create-master-user/";
          values.user.role = Master_User;
          delete values.user.liscense_count;
        }
        setLoading(true);
        const response = await api.post(url, values);
        setOpen(true);
        setLoading(false);
        props.modalClose(false);
        formik.resetForm();
        props.apiCall();
        toast.success(response.data.message);
      } catch (error) {
        setOpen2(true);
        setLoading(false);
        if (error?.response?.status === 400 && error?.response?.data) {
          if (Array.isArray(error.response.data)) {
            const errorMessage = Object.values(error.response.data)[0][0];
            showErrorMessage(errorMessage);
          } else {
            showErrorMessage(Object.values(error?.response?.data));
          }
        } else {
          showErrorMessage(error?.response?.data?.message);
        }
      }
    },
  });

  const handleConsolidator = (value) => {
    setConsolidator(value);
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <div>
      <CustomModal
        open={open}
        onCancel={handleClose}
        children={
          <SuccessMessage
            img={Tick}
            message="User added successfully !"
            desc="Thank you , user successfully created with status approved !"
            colors="bg-[#32D52B]"
            modalClose={closeModal}
          />
        }
      />
      <CustomModal
        open={open2}
        onCancel={handleClosed}
        children={
          <SuccessMessage
            img={Cross}
            message={errorMessage}
            desc="There is an issue while creating "
            colors="bg-[#F73232]"
            modalClose={closeModal1}
          />
        }
      />
      {userRole === Super_User && (
        <div className="flex justify-between gap-5">
          <CustomButton
            title="+ Add Consolidator"
            onClick={() => handleConsolidator("consolidator")}
            className={`border border-[#5D5FEF] ${
              consolidator === "consolidator"
                ? "bg-[#5D5FEF] text-white"
                : "text-[#5D5FEF] bg-white border-[#5D5FEF]"
            }  px-6 py-2  hover:bg-[#5D5FEF] text-base font-medium hover:text-white`}
          />
          <CustomButton
            title="+ Add Master User"
            onClick={() => handleConsolidator("master")}
            className={`px-6 py-2 text-base font-medium border hover:bg-[#5D5FEF] hover:text-white ${
              consolidator === "master"
                ? "bg-[#5D5FEF] text-white"
                : "text-[#5D5FEF] bg-white border-[#5D5FEF]"
            }`}
          />
        </div>
      )}
      <form
        onSubmit={formik.handleSubmit}
        className="w-full mt-8 flex flex-col gap-5"
      >
        <div className="grid grid-col-1 md:grid-cols-2 gap-x-4 gap-y-2">
          <Input
            type="text"
            placeholder="First Name"
            title="First Name"
            className="w-100"
            name="user.first_name"
            id="user.first_name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user?.first_name && formik.errors.user?.first_name
            }
          />
          <Input
            type="text"
            placeholder="Last Name"
            title="Last Name"
            className="w-100"
            name="user.last_name"
            id="user.last_name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user?.last_name && formik.errors.user?.last_name
            }
          />
          <Input
            type="text"
            placeholder="Company name"
            title="Company name"
            className="w-100"
            name="company_name"
            id="company_name"
            required
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company_name && formik.errors.company_name}
          />
          <Input
            type="email"
            placeholder="Email"
            title="Email"
            className="w-100"
            name="user.email"
            id="user.email"
            required
            value={formik.values.user.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.user?.email && formik.errors.user?.email}
          />
          <Input
            type="text"
            isShowSecureText
            placeholder="Choose Password"
            title="Choose Password"
            className="w-100"
            name="user.password"
            id="user.password"
            required
            value={formik.values.user.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user?.password && formik.errors.user?.password
            }
          />
          <DropDown
            label="What sector is your organisation in?"
            placeholder="Select Organization"
            id="organization_sector"
            name="organization_sector"
            className="w-100 py-2 rounded-xl"
            options={Organisation_type}
            multiple
            value={formik.values.organization_sector}
            onChange={(value) =>
              formik.setFieldValue("organization_sector", value)
            }
            onBlur={() => formik.setFieldTouched("organization_sector")}
            error={
              formik.touched.organization_sector &&
              formik.errors.organization_sector
            }
          />

          <DropDown
            label="Where is your organisation based?"
            placeholder="Organization based"
            id="organization_based"
            name="organization_based"
            className="w-100 py-2 rounded-xl"
            options={countries}
            value={formik.values.organization_based}
            onChange={(value) =>
              formik.setFieldValue("organization_based", value)
            }
            onBlur={() => formik.setFieldTouched("organization_based")}
            error={
              formik.touched.organization_based &&
              formik.errors.organization_based
            }
          />
          <DropDown
            label="Select number of employees"
            placeholder="No of employees"
            id="no_of_employees"
            name="no_of_employees"
            className="w-100 py-2 rounded-xl"
            options={No_Of_Employees}
            value={formik.values.no_of_employees}
            onChange={(value) => formik.setFieldValue("no_of_employees", value)}
            onBlur={() => formik.setFieldTouched("no_of_employees")}
            error={
              formik.touched.no_of_employees && formik.errors.no_of_employees
            }
          />
          {!props?.licenseDate && (
            <Input
              type="date"
              placeholder="Licence Valid till"
              title="Licence Valid till"
              className="w-100"
              name="user.valid_till"
              id="user.valid_till"
              required
              min={today}
              value={formik.values.user?.valid_till}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.user?.valid_till &&
                formik.errors.user?.valid_till
              }
            />
          )}
          {!props?.regulation && (
            <DropDown
              label="Regulations"
              placeholder="Regulations"
              multiple
              id="user.regulation"
              name="user.regulation"
              className="w-100 py-2 rounded-xl"
              options={regulation_choice}
              value={formik?.values?.user?.regulation}
              onChange={(value) =>
                formik.setFieldValue("user.regulation", value)
              }
              onBlur={() => formik.setFieldTouched("user.regulation")}
              error={
                formik.touched?.user?.regulation &&
                formik.errors?.user?.regulation
              }
            />
          )}
          {consolidator === "consolidator" && (
            <Input
              type="text"
              placeholder="Number of licence"
              title="Number of licence"
              className="w-100"
              name="user.liscense_count"
              id="user.liscense_count"
              required={consolidator === "consolidator"}
              value={formik.values?.user?.liscense_count}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.user?.liscense_count &&
                formik.errors?.user?.liscense_count
              }
            />
          )}
        </div>
        <div className="flex gap-5 items-center">
          <input
            type="checkbox"
            className="outline-none text-black cursor-pointer font-Poppins w-8 h-8"
            name="acceptTerms"
            id="acceptTerms"
            value={checkbox}
            onChange={() => setCheckbox(!checkbox)}
          />
          <p>
            I agree to your &nbsp;
            <Link to="/termsconditions" className=" text-blue-400 underline">
              Terms & Conditions
            </Link>
            &nbsp; and the use of my personal information as explained in your
            &nbsp;
            <Link to="/privacypolicy" className=" text-blue-400 underline">
              Privacy Policy
            </Link>
            &nbsp;
          </p>
        </div>

        <div className="flex gap-6 justify-end items-center">
          <CustomButton
            title="Cancel"
            onClick={handleCancel}
            className="border border-[#5D5FEF] bg-white text-[#5D5FEF] px-6 py-2  hover:bg-[#5D5FEF] text-base font-medium hover:text-white"
          />
          <CustomButton
            title={loading ? "Loading .." : "Submit"}
            type="submit"
            disabled={!checkbox}
            className={`border border-[#5D5FEF]  ${
              !checkbox
                ? "bg-[#c1c2f5] cursor-none"
                : "bg-[#5D5FEF] hover:bg-[#7c7eec]"
            } text-white px-6 py-2  text-base font-medium`}
          />
        </div>
      </form>
    </div>
  );
};

export default memo(AddUser);
