import React, { useEffect, useState } from "react";
import CustomButton from "../../Custom/CustomButton";
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../Composables/api";
import { Spin, message } from "antd";
import CustomTab from "../../Custom/CustomTab";
import Sidebar from "../../components/Sidebar";
import CustomHeader from "../../Custom/CustomHeader";
import QuestionComponent from "../../components/QuestionComponent";

const QuestionManagement = () => {
  const [data, setData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getAllQuestion = async () => {
    setLoading(true);
    try {
      const response = await api.get("/questions/");
      const approvedData = response?.data.filter(
        (user) => user?.status === "APPROVED"
      );
      const pendingDatas = response?.data.filter(
        (user) => user?.status === "PENDING"
      );
      setPendingData(pendingDatas?.reverse());
      setLoading(false);
      setData(approvedData?.reverse());
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`/questions/?question_id=${id}`);
      message.success("Question deleted successfully");
      getAllQuestion();
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  useEffect(() => {
    getAllQuestion();
  }, []);

  const handleApprove = async (id) => {
    try {
      setLoading(true);
      await api.patch(`/questions/?question_id=${id}`, {
        status: "APPROVED",
      });
      message.success("Question approved successfully");
      getAllQuestion();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      message.error(err?.response?.data?.message);
    }
  };

  const handleDecline = async (id) => {
    try {
      await api.patch(`/questions/?question_id=${id}`, {
        status: "DECLINED",
      });
      message.success("Question declined successfully");
      getAllQuestion();
      navigate("/questionmanagement");
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  const items = [
    {
      key: "1",
      label: "Approved",
      children: (
        <>
          {loading ? (
            <div className="flex h-[80vh] justify-center items-center">
              <Spin />
            </div>
          ) : (
            <QuestionComponent
              pendingData={data}
              onCancel={cancel}
              handleDelete={handleDelete}
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Pending",
      children: (
        <>
          {loading ? (
            <div className="flex h-[80vh] justify-center items-center">
              <Spin />
            </div>
          ) : (
            <QuestionComponent
              pendingData={pendingData}
              checkIcon={<CheckSquareOutlined />}
              declineIcon={<CloseSquareOutlined />}
              onCancel={cancel}
              handleDelete={handleDelete}
              handleDecline={handleDecline}
              handleApprove={handleApprove}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Sidebar>
        <CustomHeader>
          {" "}
          <CustomButton
            title="Create Question"
            onClick={() => navigate("/createquestion")}
          >
            <PlusOutlined />
          </CustomButton>
        </CustomHeader>
        <div className="p-5">
          <div className="flex flex-col gap-6">
            <CustomTab items={items} />
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default QuestionManagement;
