export const consolidator_user = "Consolidator";
export const Master_User = "Organization_Master_User";
export const Super_User = "SuperAdmin";
export const Sub_User = "Organization_Sub_User";
export const ESG_TOKEN = "esg_token";
export const QUESTION_RANGES = [
  { key: "0-25", value: 0.25 },
  { key: "26-50", value: 0.5 },
  { key: "51-75", value: 0.75 },
  { key: "76-100", value: 1 },
];
export const ANSWER_WEIGHT = {
  yes: 1,
  no: 0,
  na: 0,
  range: null,
};
