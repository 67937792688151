import { useFormik } from "formik";
import React, { useState } from "react";
import Input from "../../Custom/LoginInput";
import toast from "react-hot-toast";
import CustomButton from "../../Custom/CustomButton";
import { addSubUserValidationSchema } from "../../Assets/ValidationSchema";
import { api } from "../../Composables/api";
import CustomModal from "../../Custom/Modal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import DropDown from "../../Custom/CustomDropDown";
import { Sub_User } from "../../Assets/Constant";

const AddSubUser = (props) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, showErrorMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const closeModal = (value) => {
    setOpen(value);
  };

  const handleClosed = () => {
    setOpen2(false);
  };

  const handleCancel = () => {
    props.modalClose(false);
  };

  const closeModal1 = (value) => {
    setOpen2(value);
  };

  const initialValues = {
    user: {
      role: Sub_User,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      name: "",
    },
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addSubUserValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await api.post("/create-sub-user/", values);
        setOpen(true);
        setLoading(false);
        formik.resetForm();
        props.apiCall();
        props.modalClose(false);
        toast.success(response.data.message);
      } catch (error) {
        setOpen2(true);
        setLoading(false);
        if (error?.response?.status === 400 && error?.response?.data) {
          if (Array.isArray(error.response.data)) {
            const errorMessage = Object.values(error.response.data)[0][0];
            showErrorMessage(errorMessage);
          } else {
            showErrorMessage(Object.values(error?.response?.data));
          }
        } else {
          showErrorMessage(error?.response?.data?.message);
        }
      }
    },
  });

  const DEPARTMENT_CHOICE = [
    { value: "Procurement", label: "Procurement" },
    { value: "Facilities", label: "Facilities" },
    { value: "SeniorLeadership", label: "Senior Leadership" },
    { value: "Production", label: "Production" },
    { value: "CSR", label: "CSR" },
    { value: "IT", label: "IT" },
    { value: "HR", label: "HR" },
    { value: "Audit", label: "Audit" },
    { value: "Marketing", label: "Marketing" },
    { value: "Finance", label: "Finance" },
    { value: "ProductDevelopment", label: "Product Development" },
    { value: "RetailBanking", label: "Retail Banking" },
    { value: "Loans", label: "Loans" },
    { value: "CustomerServices", label: "Customer Services" },
    { value: "Compliance", label: "Compliance" },
    { value: "Legal", label: "Legal" },
    { value: "Sustainability", label: "Sustainability" },
    { value: "CompanySecretary", label: "Company Secretary" },
    { value: "Sales", label: "Sales" },
    { value: "Operations", label: "Operations" },
  ];

  return (
    <div>
      <CustomModal
        open={open}
        onCancel={handleClose}
        children={
          <SuccessMessage
            img={Tick}
            message="Subuser added successfully !"
            desc="Thank you , sub user will get email and password to login !"
            colors="bg-[#32D52B]"
            modalClose={closeModal}
          />
        }
      />
      <CustomModal
        open={open2}
        onCancel={handleClosed}
        children={
          <SuccessMessage
            img={Cross}
            message={errorMessage}
            desc="There is an issue while creating "
            colors="bg-[#F73232]"
            modalClose={closeModal1}
          />
        }
      />
      <form
        onSubmit={formik.handleSubmit}
        className="w-full mt-8 flex flex-col gap-5"
      >
        <div className="grid grid-col-1 gap-x-4 gap-y-2">
          <Input
            type="text"
            placeholder="First Name"
            title="First Name"
            className="w-100"
            name="user.first_name"
            id="user.first_name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user?.first_name && formik.errors.user?.first_name
            }
          />
          <Input
            type="text"
            placeholder="Last Name"
            title="Last Name"
            className="w-100"
            name="user.last_name"
            id="user.last_name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user?.last_name && formik.errors.user?.last_name
            }
          />

          <Input
            type="email"
            placeholder="Email"
            title="Email"
            className="w-100"
            name="user.email"
            id="user.email"
            required
            value={formik.values.user?.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.user?.email && formik.errors.user?.email}
          />

          <Input
            type="text"
            placeholder="Password"
            title="Password"
            className="w-100"
            name="user.password"
            id="user.password"
            required
            value={formik.values.user?.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user?.password && formik.errors.user?.password
            }
          />

          <DropDown
            label="Department"
            placeholder="Select department"
            id="user.name"
            name="user.name"
            className="w-100 py-2 rounded-xl"
            options={DEPARTMENT_CHOICE}
            value={formik.values.user?.name}
            onChange={(value) => formik.setFieldValue("user.name", value)}
            onBlur={() => formik.setFieldTouched("user.name")}
            error={formik.touched.user?.name && formik.errors.user?.name}
          />
        </div>

        <div className="flex gap-6 justify-end items-center">
          <CustomButton
            title="Cancel"
            onClick={handleCancel}
            className="border border-[#5D5FEF] bg-white text-[#5D5FEF] px-6 py-2  hover:bg-[#5D5FEF] text-base font-medium hover:text-white"
          />
          <CustomButton
            title={loading ? "Loading .." : "Submit"}
            type="submit"
            className="border border-[#5D5FEF]  bg-[#5D5FEF] text-white px-6 py-2 hover:bg-[#7c7eec] text-base font-medium"
          />
        </div>
      </form>
    </div>
  );
};

export default AddSubUser;
