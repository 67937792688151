import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../Custom/LoginInput";
import DropDown from "../../Custom/CustomDropDown";
import CustomButton from "../../Custom/CustomButton";
import Register_top from "../../Images/Register_bg_top.png";
import Register_bottom from "../../Images/Register_bg_bottom.png";
import toast from "react-hot-toast";
import { registerValidationSchema } from "../../Assets/ValidationSchema";
import CustomModal from "../../Custom/Modal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import { Organisation_type, countries } from "./Assets";
import { Master_User } from "../../Assets/Constant";
import axios from "axios";
import { regulation_choice } from "../CreateQuestion/Assets";

export default function Register() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const baseUrl = process.env.REACT_APP_API_KEY;

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const closeModal = (value) => {
    setOpen(value);
    navigate("/");
  };

  const handleClosed = () => {
    setOpen2(false);
  };

  const closeModal1 = (value) => {
    setOpen2(value);
  };
  const navigate = useNavigate();
  const No_Of_Employees = [
    { value: "0-10", label: "0-10" },
    { value: "10-25", label: "10-25" },
    { value: "25-50", label: "25-50" },
    { value: "50-100", label: "50-100" },
    { value: "100+", label: "100+" },
  ];

  const initialValues = {
    user: {
      role: Master_User,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      regulation: [],
    },
    organization_sector: [],
    organization_based: "",
    company_name: "",
    no_of_employees: "",
    department: "",
  };

  const regulations = [
    "BRSR",
    "CII",
    "IGBC",
    "LEED",
    "SDG",
    "TCFD",
    "CDP",
    "GRI",
    "SBT",
    "ESRS",
    "UNGC",
    "GoodPractice",
    "SwachhBharatMission",
    "SASB",
    "CentralBanks",
    "WBCSD",
    "WBA",
    "WHO",
    "GoodGovernancePractice",
    "MCA",
    "IFRS",
    "OECD",
    "ILO",
    "FATF",
    "UNEP",
    "CSRD",
    "UNEPSI",
    "UNGP",
  ];

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registerValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (values.user.regulation.includes("All")) {
        values.user.regulation = regulations;
      }
      try {
        setLoading(true);
        const response = await axios.post(`${baseUrl}register/`, values);
        setOpen(true);
        setLoading(false);
        toast.success(response.data.message);
      } catch (error) {
        setOpen2(true);
        setLoading(false);
        if (error?.response?.status === 400 && error?.response?.data) {
          if (Array.isArray(error.response.data)) {
            const errorMessage = Object.values(error.response.data)[0][0];
            setErrorMsg(errorMessage);
          } else {
            setErrorMsg(Object.values(error?.response?.data));
          }
        } else {
          setErrorMsg(error?.response?.data?.message);
        }
      }
    },
  });
  return (
    <>
      <CustomModal
        open={open}
        onCancel={handleClose}
        children={
          <SuccessMessage
            img={Tick}
            message="Registered Successfully !"
            desc="Thank you for your request. You will receive an email notification once your email has been approved.!"
            colors="bg-[#32D52B]"
            modalClose={closeModal}
          />
        }
      />
      <CustomModal
        open={open2}
        onCancel={handleClosed}
        children={
          <SuccessMessage
            img={Cross}
            message={errorMsg || " Registration in failed !"}
            desc="There is an issue while registering "
            colors="bg-[#F73232]"
            modalClose={closeModal1}
          />
        }
      />
      <div className=" w-full relative p-0 m-0 max-h-max">
        <div className="absolute  ">
          <img
            src={Register_top}
            alt="register_top"
            className="w-full max-h-screen"
          />
        </div>
        <div className="w-full md:top-20  top-10 flex items-center justify-center absolute z-20">
          <div className="w-[80%] md:w-[60%]  bg-white p-6  rounded-xl shadow-2xl">
            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex flex-col gap-5"
            >
              <div>
                <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
                  Sign Up
                </h1>
                <p className="text-base  text-[#B2B2B2]  font-normal">
                  Sign up to enjoy the features.
                </p>
              </div>
              <div className="grid grid-col-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <Input
                  type="text"
                  placeholder="First Name"
                  title="First Name"
                  className="w-100"
                  name="user.first_name"
                  id="user.first_name"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.user?.first_name &&
                    formik.errors.user?.first_name
                  }
                />
                <Input
                  type="text"
                  placeholder="Last Name"
                  title="Last Name"
                  className="w-100"
                  name="user.last_name"
                  id="user.last_name"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.user?.last_name &&
                    formik.errors.user?.last_name
                  }
                />
                <Input
                  type="text"
                  placeholder="Company name"
                  title="Company name"
                  className="w-100"
                  name="company_name"
                  id="company_name"
                  required
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.company_name && formik.errors.company_name
                  }
                />
                <Input
                  type="email"
                  placeholder="Email"
                  title="Email"
                  className="w-100"
                  name="user.email"
                  id="user.email"
                  required
                  value={formik.values.user.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.user?.email && formik.errors.user?.email
                  }
                />
                <Input
                  type="text"
                  isShowSecureText
                  placeholder="Choose Password"
                  title="Choose Password"
                  className="w-100"
                  name="user.password"
                  id="user.password"
                  required
                  value={formik.values.user.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.user?.password &&
                    formik.errors.user?.password
                  }
                />

                <DropDown
                  label="What sector is your organisation in?"
                  placeholder="Select Organization"
                  id="organization_sector"
                  name="organization_sector"
                  className="w-100 py-2 rounded-xl"
                  options={Organisation_type}
                  multiple
                  value={formik.values.organization_sector}
                  onChange={(value) =>
                    formik.setFieldValue("organization_sector", value)
                  }
                  onBlur={() => formik.setFieldTouched("organization_sector")}
                  error={
                    formik.touched.organization_sector &&
                    formik.errors.organization_sector
                  }
                />
                <DropDown
                  label="Where is your organisation based?"
                  placeholder="Organization based"
                  id="organization_based"
                  name="organization_based"
                  className="w-100 py-2 rounded-xl"
                  options={countries}
                  value={formik.values.organization_based}
                  onChange={(value) =>
                    formik.setFieldValue("organization_based", value)
                  }
                  onBlur={() => formik.setFieldTouched("organization_based")}
                  error={
                    formik.touched.organization_based &&
                    formik.errors.organization_based
                  }
                />
                <DropDown
                  label="Select number of employees"
                  placeholder="No of employees"
                  id="no_of_employees"
                  name="no_of_employees"
                  className="w-100 py-2 rounded-xl"
                  options={No_Of_Employees}
                  value={formik.values.no_of_employees}
                  onChange={(value) =>
                    formik.setFieldValue("no_of_employees", value)
                  }
                  onBlur={() => formik.setFieldTouched("no_of_employees")}
                  error={
                    formik.touched.no_of_employees &&
                    formik.errors.no_of_employees
                  }
                />
                <DropDown
                  label="Regulations"
                  placeholder="Regulations"
                  multiple
                  id="user.regulation"
                  name="user.regulation"
                  className="w-100 py-2 rounded-xl"
                  options={regulation_choice}
                  value={formik?.values?.user?.regulation}
                  onChange={(value) =>
                    formik.setFieldValue("user.regulation", value)
                  }
                  onBlur={() => formik.setFieldTouched("user.regulation")}
                  error={
                    formik.touched?.user?.regulation &&
                    formik.errors?.user?.regulation
                  }
                />
              </div>

              <div className="grid grid-col-1 md:grid-cols-2 gap-x-4 gap-y-2"></div>
              <div className="flex gap-5 items-center">
                <input
                  type="checkbox"
                  className="outline-none text-black cursor-pointer font-Poppins w-8 h-8"
                  name="acceptTerms"
                  id="acceptTerms"
                  value={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                />
                <p>
                  I agree to your &nbsp;
                  <Link
                    to="/termsconditions"
                    className=" text-blue-400 underline"
                  >
                    Terms & Conditions
                  </Link>
                  &nbsp; and the use of my personal information as explained in
                  your &nbsp;
                  <Link
                    to="/privacypolicy"
                    className=" text-blue-400 underline"
                  >
                    Privacy Policy
                  </Link>
                  &nbsp;
                </p>
              </div>

              <div className="flex gap-6 justify-end items-center">
                <CustomButton
                  title="Cancel"
                  onClick={() => navigate("/")}
                  className="border border-[#5D5FEF] bg-white text-[#5D5FEF] px-6 py-2  hover:bg-[#5D5FEF] text-base font-medium hover:text-white"
                />
                <CustomButton
                  type="submit"
                  disabled={!checkbox}
                  title={loading ? "Loading .." : "Submit"}
                  className={`border border-[#5D5FEF]  ${
                    !checkbox
                      ? "bg-[#c1c2f5] cursor-none"
                      : "bg-[#5D5FEF] hover:bg-[#7c7eec]"
                  } text-white px-6 py-2  text-base font-medium`}
                />
              </div>
            </form>
          </div>
        </div>
        <div className=" ">
          <img src={Register_bottom} alt="Register_bottom" className="w-full" />
        </div>
      </div>
    </>
  );
}
