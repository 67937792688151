import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    gap: 12,
    paddingBottom: 24,
    paddingTop: 24,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    backgroundColor: "#5D5FEF",
    padding: 10,
    marginTop: -24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    color: "white",
    fontSize: 24,
  },
  scoreBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: 10,
    padding: 5,
  },
  scoreText: {
    color: "#838383",
  },
  content: {
    flexDirection: "column",
    marginBottom: 10,
    paddingTop: 10,
    paddingVertical: 0,
    paddingHorizontal: 20,
  },
  title: {
    color: "#5D5FEF",
    marginBottom: 10,
  },
  regulationtitle: {
    color: "#000000",
    marginBottom: 10,
    fontWeight: 600,
  },
  paragraph: {
    marginBottom: 10,
    color: "#838383",
    fontSize: 12,
  },
  recommendation: {
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 20,
    color: "#838383",
    fontSize: 10,
  },
  recommendationTitle: {
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 6,
    color: "#838383",
    fontSize: 12,
  },
  scores: {
    marginBottom: 10,
    marginLeft: 5,
    color: "#838383",
    fontSize: 10,
    textDecoration: "none",
  },
  subSection: {
    marginBottom: 12,
    marginTop: 12,
    color: "#838383",
    fontSize: 12,
    textDecoration: "underline",
  },
  chartContainer: {
    width: 400,
    height: 200,
    marginBottom: 20,
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  list: {
    marginLeft: 20,
    fontSize: 14,
  },
});

const MyDocument = (props) => {
  const AnswerType = {
    yes: "Based on your answers to the questions, your company is adopting the following practices",
    no: "Based on your answers to the questions, your company may want to adopt the following practices",
  };

  const scoreSection = {
    Environment: props?.score?.environment?.score,
    Social: props?.score?.social?.score,
    Governance: props?.score?.governance?.score,
  };

  return (
    <>
      {props?.data ? (
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <Text style={styles.logo}>Green World</Text>
              <View style={styles.scoreBox}>
                <Text style={styles.scoreText}>Your score</Text>
                <Text style={{ fontSize: 20 }}>
                  {Math.round(props?.score?.overall)}
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text style={styles.paragraph}>
                This is a consolidated report based on a survey conducted,
                providing an integrated analysis of gathered data. It offers a
                comprehensive overview of the survey results, aiding in informed
                decision-making. Key insights and trends are highlighted to
                facilitate a deeper understanding of the findings.
              </Text>

              <View>
                <Text style={styles.regulationtitle}>
                  User Regulations : {props?.userRegulations?.join(",")}{" "}
                </Text>
              </View>
              {Object.keys(props?.data)?.map((item, index) => (
                <View style={styles.title} key={index}>
                  <Text>
                    {item} : {scoreSection[item]}{" "}
                  </Text>
                  {Object.keys(props?.data[item])
                    .filter((subItem) => props?.data[item][subItem]?.q > 0)
                    .map((subItem, subIndex) => (
                      <View key={subIndex}>
                        <Text style={styles.subSection}>
                          {subItem.replace(/(?<!^)([a-z])([A-Z])/g, "$1 $2")}
                          {props?.data[item][subItem] && (
                            <Text>
                              &nbsp;: {props?.data[item][subItem]?.score ?? ""}
                            </Text>
                          )}
                        </Text>
                        {props.data[item][subItem] &&
                          typeof props?.data[item][subItem] === "object" && (
                            <View>
                              <View></View>
                              {["yes", "no", "na", "range"].map(
                                (answerType, index) => {
                                  const recommendations =
                                    props?.data[item][subItem]?.recommendations[
                                      answerType
                                    ];
                                  if (
                                    recommendations &&
                                    recommendations.length > 0
                                  ) {
                                    return (
                                      <View key={index}>
                                        <Text style={styles.scores}>
                                          {AnswerType[answerType]}
                                        </Text>
                                        {recommendations.map(
                                          (recommendation, recIndex) => (
                                            <Text
                                              key={recIndex}
                                              style={styles.recommendation}
                                            >
                                              • {recommendation}
                                            </Text>
                                          )
                                        )}
                                      </View>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </View>
                          )}
                      </View>
                    ))}
                </View>
              ))}
            </View>
          </Page>
        </Document>
      ) : null}
    </>
  );
};

export default MyDocument;
