import React, { useEffect, useState } from "react";
import {
  QuestionOutlined,
  RiseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CustomTable from "../../Custom/CustomTable";
import { MasterReportColumn, SuperReportColumn } from "./Assets";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../MyDocument";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import api from "../../Composables/api";
import ApiCallService from "../../Services";
import { useNavigate } from "react-router-dom";
import { Master_User, Super_User } from "../../Assets/Constant";
import Sidebar from "../../components/Sidebar";
import CustomHeader from "../../Custom/CustomHeader";

const Reports = () => {
  const [reportData, setReportData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [count, setCount] = useState(null);
  const [superScore, setSuperScore] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");

  const apiCallService = new ApiCallService();

  const getAllReports = async () => {
    try {
      const response = await api.get("/calculate_final_score/");
      const reportDataArray = [response?.data[0]];
      const highestTotalScore = response?.data?.reduce((max, current) =>
        max?.total_score > current?.total_score ? max : current
      );
      setSuperScore(highestTotalScore?.total_score?.toFixed(1));
      setMasterData(reportDataArray);
      setReportData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCount = async () => {
    if (userRole === Master_User) {
      try {
        const response = await api.get("/dashboard/");
        setCount(response?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getReports = async () => {
    const response = await apiCallService.getAllReport();
    setData(response?.data[0]);
  };

  const getMasterUserSubmitInfo = async () => {
    const response = await apiCallService.getSectionWiseReport(
      `/calculate_final_score_with_subsections/`
    );
    setDisabled(response?.data[0]?.final_submission);
  };

  useEffect(() => {
    getReports();
    getAllCount();
    getAllReports();
    getMasterUserSubmitInfo();
  }, []);

  return (
    <>
      <Sidebar>
        <CustomHeader />
        <div className="p-5">
          <span className="flex flex-col max-md:max-w-full max-md:mt-10">
            <div className="flex justify-between">
              <div className="text-black text-2xl font-medium self-stretch max-md:max-w-full">
                Reports
              </div>
              {userRole === Master_User && (
                <div className="flex justify-end flex-wrap gap-5">
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        data={data?.section_subsection_wise_scores}
                        score={data?.total_score}
                        userRegulations={data?.user_regulation}
                      />
                    }
                    fileName="consolidated_report.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <>
                        <Button disabled={!data?.publish} className="">
                          <DownloadOutlined /> Download Report
                        </Button>
                      </>
                    )}
                  </PDFDownloadLink>
                  <Button
                    disabled={!disabled}
                    onClick={() => navigate("/reportdetails")}
                  >
                    Go to your score card
                  </Button>
                </div>
              )}
            </div>
          </span>

          <div className="grid grid-cols-1 mt-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10">
            {userRole !== Super_User && (
              <div className="p-5 flex flex-col gap-5  bg-[#F0F7FF] rounded-md">
                <div>
                  <h3 className="text-[#5D5FEF] font-medium text-xl">
                    Total User
                  </h3>
                  <p className="text-[#747474] w-11/12">
                    The total number of registered users on the platform.
                  </p>
                </div>
                <div className="flex justify-between">
                  <h1>{count?.subuser_count ?? ""}</h1>
                  <div className="bg-[#5D5FEF] px-3 py-2 rounded-[50%] text-white font-semibold">
                    <UserOutlined />
                  </div>
                </div>
              </div>
            )}
            {userRole !== Super_User && (
              <div className="p-5 flex flex-col gap-5  bg-[#F0F7FF] rounded-md">
                <div>
                  <h3 className="text-[#5D5FEF] font-medium text-xl">
                    Total Question
                  </h3>
                  <p className="text-[#747474] w-11/12">
                    The total number of questions available in the assessment.
                  </p>
                </div>
                <div className="flex justify-between">
                  <h1>{count?.question_count ?? "180"}</h1>
                  <div className="bg-[#5D5FEF] px-3 py-2 rounded-[50%] text-white font-semibold">
                    <QuestionOutlined />
                  </div>
                </div>
              </div>
            )}
            {userRole !== Super_User && (
              <div className="p-5 flex flex-col gap-5  bg-[#F0F7FF] rounded-md">
                <div>
                  <h3 className="text-[#5D5FEF] font-medium text-xl">
                    {userRole === Super_User
                      ? "Highest Score"
                      : "Total Answers"}
                  </h3>
                  <p className="text-[#747474] w-11/12">
                    {userRole === Super_User
                      ? "The highest score attained by any user in the assessment."
                      : "  The total number of answers submitted in the assessment."}
                  </p>
                </div>
                <div className="flex justify-between">
                  <h1>{count?.answer_count ?? ""}</h1>
                  <div className="bg-[#5D5FEF] px-3 py-2 rounded-[50%] text-white font-semibold">
                    <RiseOutlined />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-5">
            {userRole === Super_User ? (
              <CustomTable
                columnData={SuperReportColumn}
                url="/reportdetails"
                scrollWidth="1300"
                data={reportData}
              />
            ) : (
              <CustomTable
                columnData={MasterReportColumn}
                scrollWidth="1100"
                data={masterData}
              />
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default Reports;
